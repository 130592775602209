@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);

.container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1;
  height: 100vh;
  background: url(https://wallpapercave.com/wp/wp4286137.jpg);
  background-size: cover;
  
  /*background: rgb(9, 47, 128);*/
  /*background: radial-gradient(circle, rgb(245, 9, 9) 0%, rgb(255, 9, 9)  0%, rgb(180, 1, 1) 100%);*/
  
}
   
h1{
  text-align: center;
  color: red;
  font-size: 30;
  
}

.divider {
 height: 400px;
 width: 400px;
 margin: 5%;
 padding:5%;

 background-color: rgb(10, 10, 20, 0.300);
 box-shadow: 0 0 20px 0px rgb(0, 0, 0, 0.700);
 border-radius: 2%;
}
input  {
  width: 100%;
  height: 46px;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(75, 92, 89);
  color: #fff;

}

input:focus {

  border-bottom: 2px solid #26a96a;
  box-shadow: 0 1px 0 0 #26a96a;
}
label {
  position: absolute;

  color: #fff;
  -webkit-transform: translateY(18);
          transform: translateY(18);
  transition: .25s  ease-in-out;
}


input:focus +label {
  -webkit-transform: translateY(-14) scale(.8);
          transform: translateY(-14) scale(.8);
  color: #26a96a;
}


input::-webkit-input-placeholder {
  color: transparent;
}


input:-ms-input-placeholder {
  color: transparent;
}


input::placeholder {
  color: transparent;
}
button {
  padding: 10px 70px;
  margin: 20px;
  align-items: center;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  border-radius: 10px;
  cursor: pointer;
}


header{
    position: fixed;
    z-index: 999;
    top:0;
    left: 0;
    right: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background: transparent ;
    transition: all ease 0.5s;
    
}
header.black{
    background-color: #141414;
   
    
    
}

.header--logo{
    height: 25px;
}
.header--logo img{
    height: 100%;

}

.header--user{
    height: 35px;

}
.header--user img{
    height: 100%;
    border-radius: 3px;
}

*
{
    box-sizing: border-box;
}

body{
    background-color: #111;
    color: #fff;
    margin: 0;
    font-family: 'Roboto', sans-serif;


}

.lists {
    margin-top: -150px;
}

footer{

    margin: 50px 0;
    text-align: center;
}   
.loading{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: #000;
    display:flex;
    justify-content: center;
    align-items: center;
}
.featured{
    height: 100vh;
}

.featured--vertical{/* Efeito transparente imagem */
    width: inherit; /*Pega o tamanho da anterior*/
    height: inherit;
    background: linear-gradient(to top, #111 10%, transparent 90%);/*Gradiente da imagem*/
}

.featured--horizontal{/* Efeito transparente imagem */
    width: inherit;
    height: inherit;
    background: linear-gradient(to right, #111 30%, transparent 70%);/*Gradiente da imagem a direita*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-bottom: 150px;
    padding-top : 70px;
}

.featured--name{/* Nome do filme*/
    font-size: 60px;
    font-weight: bold;
}

.featured--info{/* Informação do filme*/
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
}

.featured--points{/* Pontos dos filme*/
    display: inline-block;
    color: #46d369;
    margin-right: 15px;
}

.featured--year{/* Anos dos filme*/
    display: inline-block;
    margin-right: 15px;

}

.featured--seasons{/* Temporadas dos filme*/
    display: inline-block;
    margin-right: 15px;

}


.featured--description{/* Descrição dos filme*/
    
    margin-top:15px;
    font-size: 20px;
    color: #999;
    max-width: 40%;
}

.featured--buttons{/* Botoões dos filme*/
    margin-top:15px;
}

.featured--mylistbutton,/* Estilo dos botões*/
.featured--watchbutton{
    display: inline-block;
    font-size:20px;
    font-weight: bold;
    padding:  12px 25px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 10px;
    opacity: 1;
    transition :all ease 0.2s;
}

.featured--mylistbutton:hover,
.featured--watchbutton:hover{
    opacity: 0.7;
}
.featured--watchbutton{/* Botao assistir*/
    background-color: #fff;
    color: #000;
}

.featured--mylistbutton{/* Botão Listar*/
    background-color: #333;
    color: #fff;
}

.featured--genres{/* Genero dos filme*/
    margin-top: 15px;
    font-size: 18px;
    color: #999;
}

@media (max-width:760px){/* Para Dispositivos móveis*/
    .featured{
        height: 90vh;
    }
    .featured--name{
        font-size:40px;
    }
    .featured--info{
        font-size:16px;        
    }
    .featured--description{
        font-size:14px; 
        max-width: 100%;
        margin-right: 30px;    
    }
    .featured--mylistbutton,
    .featured--watchbutton{
        font-size:10px; 
    }
    .featured--genres{
        font-size:14px;        
    }
}
@media (max-width:760px){
    .featured{
        height: 90vh;
    }
    .featured--name {
        font-size: 40px;
    }
    .featured--info{
        font-size: 16px;
    }
    .featured--description {
        font-size: 16px;
        max-width: 100%;
        margin-right: 30px;

    }
    .featured--watchbutton,
    .featured--mylistbutton{
        font-size: 16px;
    }
    .featured--genres{
        font-size: 14px;
    }


}
.movieRow{
    margin-bottom: 30px;
}

.movieRow h2 {
    margin: 0px 0px 0px 30px;
}

.movieRow--listarea {
    overflow-x : hidden;
    padding-left: 20px;
    
}

.movieRow--list {
    transition : all ease 0.6s;/*Velocidade da transição da lista*/
}

.movieRow--item{/*Lista de filmes*/
    display:inline-block;
    width:130px;
    cursor: pointer;
    
}

.movieRow--item img{
   width: 100%;
   -webkit-transform: scale(0.9);
           transform: scale(0.9);
   transition : all ease 0.2s;
   
}


.movieRow--item img:hover{
    -webkit-transform: scale(1);
            transform: scale(1);
 }


 /*Estilo das Setas*/
 .movieRow-left,
 .movieRow-right {
    position:absolute;   
    width: 40px;
    height: 190px;/*Tamando das setas*/
    background-color:rgba(0,0,0,0.6);
    z-index: 99;/*Posicionamento setas*/
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;/*Tira espaço extras setas*/
    cursor:pointer;
    opacity: 0;
    transition : all ease 0.2s;
}

.movieRow-left{
    left: 0;
}

.movieRow-right{
   right: 0;
}
/*Efeito para aparecer as setas apenas na hora que passar o mouse nas listas de filmes*/
.movieRow:hover .movieRow-left,
.movieRow:hover .movieRow-right{
   opacity: 1;
}
/*  Para */
@media (max-width: 760px){
    .movieRow-left,
    .movieRow-right {
    opacity: 1;
    }
}

