header{
    position: fixed;
    z-index: 999;
    top:0;
    left: 0;
    right: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background: transparent ;
    transition: all ease 0.5s;
    
}
header.black{
    background-color: #141414;
   
    
    
}

.header--logo{
    height: 25px;
}
.header--logo img{
    height: 100%;

}

.header--user{
    height: 35px;

}
.header--user img{
    height: 100%;
    border-radius: 3px;
}