.featured{
    height: 100vh;
}

.featured--vertical{/* Efeito transparente imagem */
    width: inherit; /*Pega o tamanho da anterior*/
    height: inherit;
    background: linear-gradient(to top, #111 10%, transparent 90%);/*Gradiente da imagem*/
}

.featured--horizontal{/* Efeito transparente imagem */
    width: inherit;
    height: inherit;
    background: linear-gradient(to right, #111 30%, transparent 70%);/*Gradiente da imagem a direita*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-bottom: 150px;
    padding-top : 70px;
}

.featured--name{/* Nome do filme*/
    font-size: 60px;
    font-weight: bold;
}

.featured--info{/* Informação do filme*/
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
}

.featured--points{/* Pontos dos filme*/
    display: inline-block;
    color: #46d369;
    margin-right: 15px;
}

.featured--year{/* Anos dos filme*/
    display: inline-block;
    margin-right: 15px;

}

.featured--seasons{/* Temporadas dos filme*/
    display: inline-block;
    margin-right: 15px;

}


.featured--description{/* Descrição dos filme*/
    
    margin-top:15px;
    font-size: 20px;
    color: #999;
    max-width: 40%;
}

.featured--buttons{/* Botoões dos filme*/
    margin-top:15px;
}

.featured--mylistbutton,/* Estilo dos botões*/
.featured--watchbutton{
    display: inline-block;
    font-size:20px;
    font-weight: bold;
    padding:  12px 25px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 10px;
    opacity: 1;
    transition :all ease 0.2s;
}

.featured--mylistbutton:hover,
.featured--watchbutton:hover{
    opacity: 0.7;
}
.featured--watchbutton{/* Botao assistir*/
    background-color: #fff;
    color: #000;
}

.featured--mylistbutton{/* Botão Listar*/
    background-color: #333;
    color: #fff;
}

.featured--genres{/* Genero dos filme*/
    margin-top: 15px;
    font-size: 18px;
    color: #999;
}

@media (max-width:760px){/* Para Dispositivos móveis*/
    .featured{
        height: 90vh;
    }
    .featured--name{
        font-size:40px;
    }
    .featured--info{
        font-size:16px;        
    }
    .featured--description{
        font-size:14px; 
        max-width: 100%;
        margin-right: 30px;    
    }
    .featured--mylistbutton,
    .featured--watchbutton{
        font-size:10px; 
    }
    .featured--genres{
        font-size:14px;        
    }
}
@media (max-width:760px){
    .featured{
        height: 90vh;
    }
    .featured--name {
        font-size: 40px;
    }
    .featured--info{
        font-size: 16px;
    }
    .featured--description {
        font-size: 16px;
        max-width: 100%;
        margin-right: 30px;

    }
    .featured--watchbutton,
    .featured--mylistbutton{
        font-size: 16px;
    }
    .featured--genres{
        font-size: 14px;
    }


}